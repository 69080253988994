import { useState } from 'react';
import { Language } from '@/components/LanguageSwitcher';
import { Navbar } from '@/components/Navbar';
import { Hero } from '@/components/Hero';
import { Services } from '@/components/Services';
import { Partners } from '@/components/Partners';
import { Contact } from '@/components/Contact';
import { Founder } from '@/components/Founder';

const Index = () => {
  const [language, setLanguage] = useState<Language>('pt');

  return (
    <div className="min-h-screen">
      <Navbar language={language} onLanguageChange={setLanguage} />
      <main className="pt-16">
        <Hero language={language} />
        <Services language={language} />
        <Founder language={language} />
        <Partners language={language} />
        <Contact language={language} />
      </main>
    </div>
  );
};

export default Index;