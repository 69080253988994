
import { Language } from '../LanguageSwitcher';
import { MapPin, Phone } from 'lucide-react';

interface ContactInfoProps {
  language: Language;
  content: {
    [key in Language]: {
      address: string;
      city: string;
      phone: string;
      findUs: string;
    };
  };
}

export const ContactInfo = ({ language, content }: ContactInfoProps) => {
  const mapUrl = "https://maps.app.goo.gl/YsYNifTKY7RzrGYg7";

  return (
    <div className="space-y-8">
      <div className="bg-white p-6 rounded-lg shadow-sm border">
        <h3 className="text-xl font-semibold mb-6">TB Consultores</h3>
        <div className="space-y-4">
          <div className="flex items-start space-x-3 text-gray-600">
            <MapPin className="h-5 w-5 text-primary flex-shrink-0 mt-1" />
            <div>
              <p>{content[language].address}</p>
              <p>{content[language].city}</p>
            </div>
          </div>
          <div className="flex items-center space-x-3 text-gray-600">
            <Phone className="h-5 w-5 text-primary flex-shrink-0" />
            <p>{content[language].phone}</p>
          </div>
        </div>
        <a
          href={mapUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block mt-6 text-primary hover:text-primary/80 font-medium transition-colors"
        >
          {content[language].findUs} →
        </a>
      </div>
      <div className="aspect-video rounded-lg overflow-hidden shadow-sm border">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12421.135019891613!2d-9.034735!3d38.894626!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1928b62616102f%3A0x8c83d0b3289e729c!2sTB%20Consultores%2C%20Contabilidade%20e%20Gest%C3%A3o%20Lda.!5e0!3m2!1sen!2spt!4v1741472726129!5m2!1sen!2spt"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="TB Consultores Location"
        />
      </div>
    </div>
  );
};
