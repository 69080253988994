import { Language } from './LanguageSwitcher';
import { LinkedInLogoIcon } from '@radix-ui/react-icons';

interface FounderProps {
  language: Language;
}

export const Founder = ({ language }: FounderProps) => {
  const content = {
    pt: {
      title: 'Fundador',
      name: 'António Belo',
      education: [
        'Pós-Graduação em Direito Fiscal pela Faculdade de Direito da Universidade de Lisboa',
        'Mini MBA em Gestão pela Cegid Academy',
        'Mestrado em Controlo Financeiro pelo ISCAL-Lisbon Accounting and Business School',
        'Pós-Graduação em Direção e Gestão de Recursos Humanos pelo Instituto Superior de Gestão',
        'Licenciatura em Contabilidade pelo ISCAL-Lisbon Accounting and Business School'
      ],
      experience: 'Com uma carreira notável em consultoria e gestão financeira, António Belo atualmente atua como Consultor de Gestão na Intra Comércio Internacional e Consultoria. Anteriormente, ocupou posições de liderança como Diretor Financeiro e Administrativo na Konica Minolta Business Solutions Portugal, Diretor Financeiro no Hospital Distrital de Santarém, e Presidente Financeiro Fundador da International Coach Federation Portugal. Sua experiência inclui também passagens como Controller na Provimi e Gerente de Serviços Administrativos e Financeiros na Tertir, demonstrando uma sólida trajetória em gestão financeira e consultoria empresarial.',
    },
    en: {
      title: 'Founder',
      name: 'António Belo',
      education: [
        'Postgraduate Degree in Tax Law from Faculty of Law, University of Lisbon',
        'Mini MBA in Management from Cegid Academy',
        'Master of Science in Financial Controller from ISCAL-Lisbon Accounting and Business School',
        'Postgraduate in Direction and Management of Human Resources from Instituto Superior de Gestão',
        'Bachelor\'s degree in Accounting from ISCAL-Lisbon Accounting and Business School'
      ],
      experience: 'With a remarkable career in consulting and financial management, António Belo currently serves as a Management Advisor at Intra Comércio Internacional e Consultoria. Previously, he held leadership positions as Finance and Administration Director at Konica Minolta Business Solutions Portugal, Financial Director at District Hospital of Santarém, and Founder and Financial President at International Coach Federation Portugal. His experience also includes roles as Controller at Provimi and Manager of Administrative and Financial Services at Tertir, demonstrating a solid track record in financial management and business consulting.',
    },
  };

  return (
    <section className="py-24 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-xl p-12">
          <div className="text-center mb-10">
            <h2 className="text-4xl font-bold text-[#1A1F2C] mb-3">
              {content[language].title}
            </h2>
            <div className="flex items-center justify-center gap-3 mb-2">
              <h3 className="text-3xl font-semibold text-[#FAD019]">
                {content[language].name}
              </h3>
              <a
                href="https://www.linkedin.com/in/antónio-belo-5a52401/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 transition-colors"
                aria-label="LinkedIn Profile"
              >
                <LinkedInLogoIcon className="w-6 h-6" />
              </a>
            </div>
            <div className="w-24 h-1 bg-gradient-to-r from-[#FEF7CD] to-[#FAD019] mx-auto"></div>
          </div>
          <div className="space-y-8 text-gray-600">
            <div className="space-y-4">
              {content[language].education.map((edu, index) => (
                <p key={index} className="text-lg leading-relaxed border-l-4 border-[#FAD019] pl-6 py-2">
                  {edu}
                </p>
              ))}
            </div>
            <p className="text-lg leading-relaxed">
              {content[language].experience}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};