import { useState } from 'react';
import { Language } from '@/components/LanguageSwitcher';
import { Navbar } from '@/components/Navbar';

const Insights = () => {
  const [language, setLanguage] = useState<Language>('pt');

  const content = {
    pt: {
      title: 'Artigos e Insights',
      description: 'Explore nossos artigos sobre contabilidade, consultoria e empreendedorismo.',
    },
    en: {
      title: 'Articles and Insights',
      description: 'Explore our articles about accounting, consulting, and entrepreneurship.',
    },
  };

  return (
    <div className="min-h-screen">
      <Navbar language={language} onLanguageChange={setLanguage} />
      <main className="pt-32 container mx-auto px-4">
        <h1 className="text-4xl font-bold text-primary mb-6">{content[language].title}</h1>
        <p className="text-xl text-gray-600 mb-12">{content[language].description}</p>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {/* Placeholder for Medium feed */}
          {[1, 2, 3].map((i) => (
            <div key={i} className="bg-white rounded-lg shadow-lg p-6 animate-fade-up">
              <div className="bg-muted h-48 rounded-lg mb-4"></div>
              <h3 className="text-xl font-semibold mb-2">Article Title {i}</h3>
              <p className="text-gray-600">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
};

export default Insights;