
import { useState } from 'react';
import { Button } from "@/components/ui/button";
import { Language } from '../LanguageSwitcher';
import { useToast } from "@/hooks/use-toast";
import emailjs from '@emailjs/browser';

interface ContactFormProps {
  language: Language;
}

export const ContactForm = ({ language }: ContactFormProps) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  const content = {
    pt: {
      name: 'Nome',
      email: 'Email',
      phone: 'Telefone',
      message: 'Mensagem',
      submit: 'Enviar',
      successMessage: 'Mensagem enviada com sucesso!',
      errorMessage: 'Erro ao enviar mensagem. Tente novamente.',
    },
    en: {
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      message: 'Message',
      submit: 'Submit',
      successMessage: 'Message sent successfully!',
      errorMessage: 'Error sending message. Please try again.',
    },
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;
    
    setIsSubmitting(true);

    try {
      await emailjs.send(
        'service_cjnv9sx',
        'template_rucvtuq',
        {
          from_name: formData.name,
          from_email: formData.email,
          phone: formData.phone,
          message: formData.message,
        },
        'zRSqThKBVYGwoP0G1'
      );

      toast({
        title: content[language].successMessage,
        duration: 3000,
      });

      setFormData({
        name: '',
        email: '',
        phone: '',
        message: '',
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast({
        title: content[language].errorMessage,
        variant: "destructive",
        duration: 3000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <input
          type="text"
          name="name"
          placeholder={content[language].name}
          value={formData.name}
          onChange={handleInputChange}
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary/50"
          required
        />
      </div>
      <div>
        <input
          type="email"
          name="email"
          placeholder={content[language].email}
          value={formData.email}
          onChange={handleInputChange}
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary/50"
          required
        />
      </div>
      <div>
        <input
          type="tel"
          name="phone"
          placeholder={content[language].phone}
          value={formData.phone}
          onChange={handleInputChange}
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary/50"
        />
      </div>
      <div>
        <textarea
          name="message"
          placeholder={content[language].message}
          value={formData.message}
          onChange={handleInputChange}
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary/50 min-h-[120px]"
          required
        />
      </div>
      <Button 
        type="submit" 
        className="w-full"
        disabled={isSubmitting}
      >
        {isSubmitting ? '...' : content[language].submit}
      </Button>
    </form>
  );
};
