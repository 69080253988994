import { Language } from './LanguageSwitcher';
import { ContactInfo } from './contact/ContactInfo';
import { ContactForm } from './contact/ContactForm';

interface ContactProps {
  language: Language;
}

export const Contact = ({ language }: ContactProps) => {
  const content = {
    pt: {
      title: 'Contacte-nos',
      address: 'Praceta Dom Álvaro Vaz de Almada 2/E Lote 10',
      city: '2615-358 Alverca do Ribatejo',
      phone: '+351 262 841 176',
      findUs: 'Encontre-nos no mapa',
    },
    en: {
      title: 'Contact Us',
      address: 'Praceta Dom Álvaro Vaz de Almada 2/E Lote 10',
      city: '2615-358 Alverca do Ribatejo',
      phone: '+351 262 841 176',
      findUs: 'Find us on the map',
    },
  };

  return (
    <section id="contact" className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-primary mb-12">
          {content[language].title}
        </h2>
        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
          <ContactInfo language={language} content={content} />
          <ContactForm language={language} />
        </div>
      </div>
    </section>
  );
};
