
import { Language } from './LanguageSwitcher';
import { ChevronDown } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Button } from './ui/button';
import { HashLink } from 'react-router-hash-link';

interface HeroProps {
  language: Language;
}

export const Hero = ({ language }: HeroProps) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      
      // Calculate when to start fading out (80% of the first viewport)
      const fadeStart = windowHeight * 0.8;
      
      if (scrollPosition > fadeStart) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      
      console.log('Scroll position:', scrollPosition, 'Fade start:', fadeStart, 'Is visible:', isVisible);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const content = {
    pt: {
      title: 'Excelência em Contabilidade e Gestão',
      yearText: 'Desde 1996',
      subtitle: 'Soluções avançadas para o seu negócio',
      companyName: 'TB Consultores Contabilidade e Gestão',
      contactButton: 'Contactar'
    },
    en: {
      title: 'Excellence in Accounting and Management',
      yearText: 'Since 1996',
      subtitle: 'Advanced technological solutions for your business',
      companyName: 'TB Consultores Contabilidade e Gestão',
      contactButton: 'Contact Us'
    },
  };

  return (
    <div className="min-h-[80vh] flex items-center justify-center bg-gradient-to-br from-primary/5 to-primary/10">
      <div className="container mx-auto px-4 text-center animate-fade-up">
        <h1 className="text-4xl md:text-6xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-primary via-primary/80 to-primary leading-tight">
          {content[language].title}
        </h1>
        <p className="text-xl md:text-2xl font-serif text-primary/80 mb-6 italic">
          {content[language].yearText}
        </p>
        <p className="text-xl md:text-2xl text-gray-600 max-w-2xl mx-auto font-light mb-8">
          {content[language].subtitle}
        </p>
        <HashLink smooth to="#contact">
          <Button
            className="bg-[#FAD019] hover:bg-[#FAD019]/90 text-primary font-medium px-8 py-6 text-lg"
          >
            {content[language].contactButton}
          </Button>
        </HashLink>
      </div>
      <div 
        className={`fixed bottom-8 left-1/2 transform -translate-x-1/2 transition-all duration-500 ${
          isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
      >
        <ChevronDown 
          className="animate-bounce text-primary w-8 h-8"
          onClick={() => window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' })}
        />
      </div>
    </div>
  );
};
