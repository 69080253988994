import { Language } from './LanguageSwitcher';

interface PartnersProps {
  language: Language;
}

export const Partners = ({ language }: PartnersProps) => {
  const partners = [
    { name: 'Primavera', logo: '/lovable-uploads/b613ce0c-7030-4728-84cd-f95dddc79961.png' },
    { name: 'Konica', logo: '/lovable-uploads/ed0153a6-eb36-4707-a590-cf74bea572f7.png' },
    { name: 'Ciben', logo: '/lovable-uploads/a1772cc7-402d-4522-a581-3f9f076f1d77.png' },
  ];

  return (
    <section className="py-16 bg-muted">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-primary mb-12">
          {language === 'pt' ? 'Parceiros de Confiança' : 'Trusted Partners'}
        </h2>
        <div className="flex flex-wrap justify-center items-center gap-12">
          {partners.map((partner, index) => (
            <div
              key={index}
              className="w-40 h-20 bg-white rounded-lg shadow-md flex items-center justify-center p-4 animate-fade-up"
              style={{ animationDelay: `${index * 100}ms` }}
            >
              <img
                src={partner.logo}
                alt={partner.name}
                className="max-w-full max-h-full object-contain"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};