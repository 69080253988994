import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Menu, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { LanguageSwitcher, Language } from './LanguageSwitcher';

interface NavbarProps {
  language: Language;
  onLanguageChange: (lang: Language) => void;
}

export const Navbar = ({ language, onLanguageChange }: NavbarProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems = {
    pt: [
      { label: 'Início', path: '/', isHash: false },
      { label: 'Serviços', path: '/#services', isHash: true },
      { label: 'Artigos', path: '/insights', isHash: false },
      { label: 'Contacto', path: '/#contact', isHash: true },
    ],
    en: [
      { label: 'Home', path: '/', isHash: false },
      { label: 'Services', path: '/#services', isHash: true },
      { label: 'Insights', path: '/insights', isHash: false },
      { label: 'Contact', path: '/#contact', isHash: true },
    ],
  };

  const scrollWithOffset = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100; // Increased offset to account for the fixed header
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
  };

  return (
    <nav className="fixed top-0 w-full bg-white shadow-sm z-50">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <Link to="/" className="flex items-center space-x-2">
            <img 
              src="/lovable-uploads/567c65de-7eea-4ff6-87ee-8273b52f0543.png" 
              alt="TB Consultores Logo" 
              className="h-12 w-auto"
            />
          </Link>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-8">
            <div className="flex space-x-6">
              {menuItems[language].map((item) => (
                item.isHash ? (
                  <HashLink
                    key={item.path}
                    smooth
                    to={item.path}
                    scroll={scrollWithOffset}
                    className="text-gray-600 hover:text-primary transition-colors"
                  >
                    {item.label}
                  </HashLink>
                ) : (
                  <Link
                    key={item.path}
                    to={item.path}
                    className="text-gray-600 hover:text-primary transition-colors"
                  >
                    {item.label}
                  </Link>
                )
              ))}
            </div>
            <LanguageSwitcher onLanguageChange={onLanguageChange} currentLanguage={language} />
          </div>

          {/* Mobile Menu Button */}
          <Button
            variant="ghost"
            className="md:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </Button>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden py-4">
            <div className="flex flex-col space-y-4">
              {menuItems[language].map((item) => (
                item.isHash ? (
                  <HashLink
                    key={item.path}
                    smooth
                    to={item.path}
                    scroll={scrollWithOffset}
                    className="text-gray-600 hover:text-primary transition-colors"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {item.label}
                  </HashLink>
                ) : (
                  <Link
                    key={item.path}
                    to={item.path}
                    className="text-gray-600 hover:text-primary transition-colors"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {item.label}
                  </Link>
                )
              ))}
              <div className="pt-4">
                <LanguageSwitcher onLanguageChange={onLanguageChange} currentLanguage={language} />
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};