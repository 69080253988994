import { useState } from 'react';
import { Button } from '@/components/ui/button';

export type Language = 'pt' | 'en';

interface LanguageSwitcherProps {
  onLanguageChange: (lang: Language) => void;
  currentLanguage: Language;
}

export const LanguageSwitcher = ({ onLanguageChange, currentLanguage }: LanguageSwitcherProps) => {
  return (
    <div className="flex gap-2">
      <Button
        variant={currentLanguage === 'pt' ? 'default' : 'outline'}
        onClick={() => onLanguageChange('pt')}
        className="w-12"
      >
        PT
      </Button>
      <Button
        variant={currentLanguage === 'en' ? 'default' : 'outline'}
        onClick={() => onLanguageChange('en')}
        className="w-12"
      >
        EN
      </Button>
    </div>
  );
};