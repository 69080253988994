import { Language } from './LanguageSwitcher';
import { Calculator, Users, LineChart, Building } from 'lucide-react';

interface ServicesProps {
  language: Language;
}

export const Services = ({ language }: ServicesProps) => {
  const services = {
    pt: [
      {
        title: 'Contabilidade',
        description: 'Serviços contábeis completos e precisos para o seu negócio',
        icon: Calculator,
      },
      {
        title: 'Recursos Humanos',
        description: 'Gestão eficiente de pessoal e processamento de salários',
        icon: Users,
      },
      {
        title: 'Consultoria de Gestão',
        description: 'Orientação estratégica para otimizar seu negócio',
        icon: LineChart,
      },
      {
        title: 'Criação de Empresas',
        description: 'Suporte completo para iniciar seu negócio',
        icon: Building,
      },
    ],
    en: [
      {
        title: 'Accounting',
        description: 'Complete and accurate accounting services for your business',
        icon: Calculator,
      },
      {
        title: 'Human Resources',
        description: 'Efficient personnel management and payroll processing',
        icon: Users,
      },
      {
        title: 'Management Consulting',
        description: 'Strategic guidance to optimize your business',
        icon: LineChart,
      },
      {
        title: 'Business Creation',
        description: 'Complete support to start your business',
        icon: Building,
      },
    ],
  };

  return (
    <section id="services" className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-primary mb-12">
          {language === 'pt' ? 'Nossos Serviços' : 'Our Services'}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services[language].map((service, index) => (
            <div
              key={index}
              className="p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow animate-fade-up"
              style={{ animationDelay: `${index * 100}ms` }}
            >
              <service.icon className="w-12 h-12 text-primary mb-4" />
              <h3 className="text-xl font-semibold mb-3">{service.title}</h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};